import React from 'react'
import styled from 'styled-components'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { gaPath, gaEvent } from '../ga'

import { useSpring, animated } from 'react-spring'

const CollapseMenu = props => {
  const { open } = useSpring({ open: props.navbarState ? 0 : 1 })

  if (props.navbarState === true) {
    return (
      <CollapseWrapper
        style={{
          transform: open
            .interpolate({
              range: [0, 0.2, 0.3, 1],
              output: [0, -20, 0, -200],
            })
            .interpolate(openValue => `translate3d(0, ${openValue}px, 0`),
        }}
      >
        <NavLinks>
          <li>
            <a
              href="#lords-prayer-journal"
              onClick={() => {
                props.handleNavbar()
                gaPath('/lords-prayer-journal')
              }}
            >
              The Lord's Prayer Journal
            </a>
          </li>
          <li>
            <a
              href="#testimonials"
              onClick={() => {
                props.handleNavbar()
                gaPath('/testimonials')
              }}
            >
              Testimonials
            </a>
          </li>
          <li>
            <OutboundLink
              href={props.amazonLink}
              onClick={() => {
                props.handleNavbar()
                gaEvent('CollapseMenu', 'Clicked: Amazon')
              }}
            >
              Buy Now on Amazon
            </OutboundLink>
          </li>
          <li>
            <a
              href="/store"
              onClick={() => {
                props.handleNavbar()
                gaPath('/store')
              }}
            >
              4HisGlory! Store
            </a>
          </li>
          <li>
            <a
              href="#author"
              onClick={() => {
                props.handleNavbar()
                gaPath('/about')
              }}
            >
              About the Author
            </a>
          </li>
        </NavLinks>
      </CollapseWrapper>
    )
  }
  return null
}

export default CollapseMenu

const CollapseWrapper = styled(animated.div)`
  background: #2d3436;
  position: fixed;
  top: 3rem;
  left: 0;
  right: 0;
  z-index: 9;
`

const NavLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 2rem 2rem;
  margin-bottom: 0;

  & li {
    transition: all 300ms linear 0s;
  }

  & a {
    font-size: 1.4rem;
    line-height: 3;
    color: #dfe6e9;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #fdcb6e;
      border-bottom: 1px solid #fdcb6e;
    }
  }
`
