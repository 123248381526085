import React from 'react'
import '../assets/scss/main.scss'

import Navbar from './navbar/Navbar'
import Header from './Header'
import Footer from './Footer'

class Template extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: 'is-loading',
      navbarOpen: false,
    }
  }

  handleNavbar = () => {
    this.setState({ navbarOpen: !this.state.navbarOpen })
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' })
    }, 100)
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  render() {
    const { children } = this.props

    return (
      <div className={`body ${this.state.loading}`}>
        <Navbar
          navbarState={this.state.navbarOpen}
          handleNavbar={this.handleNavbar}
          amazonLink={this.props.amazonLink}
        />
        {!this.props.hideHeader && <Header />}
        {children}
        <Footer amazonLink={this.props.amazonLink} />
      </div>
    )
  }
}

export default Template
