import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { gaPath, gaEvent } from './ga'

class Footer extends React.Component {
  render() {
    return (
      <section id="footer">
        <h2
          onClick={() => {
            window.location.href = '/#'
            gaPath('/')
          }}
          onKeyDown={() => {
            window.location.href = '/#'
            gaPath('/')
          }}
          role="presentation"
        >
          May the Lord use this journal to bless you and bring Him glory.
        </h2>
        <ul className="copyright">
          <li>&copy; 4HisGlory!</li>
          <li>
            <a
              href="/privacy-policy"
              onClick={() => {
                gaPath('/privacy-policy')
              }}
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <OutboundLink
              href={this.props.amazonLink}
              onClick={() => {
                gaEvent('Footer', 'Clicked: Amazon')
              }}
            >
              Amazon Product Page
            </OutboundLink>
          </li>
        </ul>
      </section>
    )
  }
}

export default Footer
