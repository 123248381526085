import React from 'react'
import styled from 'styled-components'
import { useSpring, animated, config } from 'react-spring'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { gaPath, gaEvent } from '../ga'

import Brand from './Brand'
import BurgerMenu from './BurgerMenu'
import CollapseMenu from './CollapseMenu'

const Navbar = props => {
  const barAnimation = useSpring({
    from: { transform: 'translate3d(0, -10rem, 0)' },
    transform: 'translate3d(0, 0, 0)',
  })

  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 15px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 800,
    config: config.wobbly,
  })

  return (
    <>
      <NavBar style={barAnimation}>
        <FlexContainer>
          <Brand />
          <NavLinks style={linkAnimation}>
            <a
              href="#lords-prayer-journal"
              onClick={() => gaPath('/lords-prayer-journal')}
            >
              The Lord's Prayer Journal
            </a>
            <a href="#testimonials" onClick={() => gaPath('/testimonials')}>
              Testimonials
            </a>
            <OutboundLink
              href={props.amazonLink}
              onClick={() => {
                gaEvent('Navbar', 'Clicked: Amazon')
              }}
            >
              Buy Now
            </OutboundLink>
            <a href="/store" onClick={() => gaPath('/store')}>
              Store
            </a>
            <a href="#author" onClick={() => gaPath('/about')}>
              About
            </a>
          </NavLinks>
          <BurgerWrapper
            onClick={() => {
              gaEvent('Navbar', 'CollapseMenu: Toggled')
            }}
          >
            <BurgerMenu
              navbarState={props.navbarState}
              handleNavbar={props.handleNavbar}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu
        navbarState={props.navbarState}
        handleNavbar={props.handleNavbar}
        amazonLink={props.amazonLink}
      />
    </>
  )
}

export default Navbar

const NavBar = styled(animated.nav)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(31, 51, 82, 0.8);
  z-index: 10;
  font-size: 1rem;
`

const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 1.5rem;
  justify-content: space-between;
  height: 3rem;
`

const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;

  & a {
    color: #dfe6e9;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    margin: 0 1.3rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #fdcb6e;
      border-bottom: 1px solid #fdcb6e;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`

const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: 769px) {
    display: none;
  }
`
