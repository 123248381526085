const gaID = ['G-B304SPKFC6', 'UA-158840871-1']
const paths = [
  { page_title: 'Home', page_path: '/' },
  { page_title: 'Home-LPJ', page_path: '/lords-prayer-journal' },
  { page_title: 'Home-Testimonials', page_path: '/testimonials' },
  { page_title: 'Home-Features', page_path: '/features' },
  { page_title: 'Home-About', page_path: '/about' },
  { page_title: 'Privacy Policy', page_path: '/privacy-policy' },
  { page_title: '404', page_path: '/404' },
  { page_title: 'Store', page_path: '/store' },
]

function gaPath(page_path) {
  const path = paths.filter(d => {
    return d.page_path === page_path
  })[0] || { page_path: page_path }
  gaID.forEach(id => {
    typeof window !== 'undefined' && window.gtag('config', id, path)
    typeof window !== 'undefined' && window.console.log(path)
  })
}

function gaEvent(category, evtType, label, value) {
  const params = {}
  if (category) params.event_category = category
  if (label) params.event_label = label
  if (value) params.value = value

  typeof window !== 'undefined' && window.gtag('event', evtType, params)
  typeof window !== 'undefined' && window.console.log(evtType, params)
}

export { gaID, gaPath, gaEvent }
