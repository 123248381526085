import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout hideHeader={true}>
    <div class="no-header-huge align-center">
      <h1 class="">NOT FOUND</h1>
      <p>The URL doesn't exist.</p>
      <p>
        <a href="/#" className="button special">
          Go to 4HisGlory! homepage
        </a>
      </p>
      <h2 class="no-header align-center"> </h2>
    </div>
  </Layout>
)

export default NotFoundPage
